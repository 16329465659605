import React from 'react'
import styled from 'styled-components'

const StyledAnchor = styled.a`
  display: inline-flex;
  justify-content: center;
  padding: 14px 48px 16px;
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
  border-radius: 40px;
  cursor: pointer;

  span.arrow-icon {
    transition: transform 400ms ease-in;
  }

  &.primary {
    background-color: ${(props) => props.theme.v2.button.primary.bgColor};
    color: ${(props) => props.theme.v2.button.primary.textColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.primary.hoverColor};
      color: ${(props) => props.theme.v2.button.primary.textColor};
    }

    span.arrow-icon {
      background-image: url('/assets/img/icons/link-arrow-black.svg');
    }
  }

  &.secondary {
    border: 1px solid ${(props) => props.theme.v2.button.secondary.borderColor};
    background-color: ${(props) => props.theme.v2.button.secondary.bgColor};
    color: ${(props) => props.theme.v2.button.secondary.textColor};
    border-color: ${(props) => props.theme.v2.button.secondary.borderColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.secondary.hoverColor};
      border-color: ${(props) => props.theme.v2.button.secondary.borderHoverColor};
    }
  }

  &.dark {
    background-color: ${(props) => props.theme.v2.button.dark.bgColor};
    color: ${(props) => props.theme.v2.button.dark.textColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.dark.hoverColor};
      color: ${(props) => props.theme.v2.button.dark.textColor};
    }

    span.arrow-icon {
      background-image: url('/assets/img/icons/link-arrow-white.svg');
      transition: transform 1s ease-in;
    }
  }

  &.bright {
    background-color: ${(props) => props.theme.v2.button.bright.bgColor};
    color: ${(props) => props.theme.v2.button.bright.textColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.bright.hoverColor};
    }
    &.disabled,
    &.disabled:hover {
      background-color: ${(props) => props.theme.v2.button.bright.bgColor};
      opacity: 0.8;
      cursor: not-allowed;
    }

    span.arrow-icon {
      background-image: url('/assets/img/icons/link-arrow-blue.svg');
    }
  }

  &.white {
    background-color: ${(props) => props.theme.v2.button.white.bgColor};
    color: ${(props) => props.theme.v2.button.white.textColor};

    &:hover {
      opacity: 0.8;
    }
    &.disabled,
    &.disabled:hover {
      background-color: ${(props) => props.theme.v2.button.white.bgColor};
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  &.bright-border {
    border: 1px solid ${(props) => props.theme.v2.button.brightBorder.borderColor};
    color: ${(props) => props.theme.v2.button.brightBorder.textColor};
    background-color: ${(props) => props.theme.v2.button.brightBorder.bgColor};

    &:hover {
      color: ${(props) => props.theme.v2.button.brightBorder.hoverColor};
      border: 1px solid ${(props) => props.theme.v2.button.brightBorder.hoverBorderColor};
    }
  }

  &.fullwidth {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 568px;
  }

  &.fullwidth-less-padding {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    max-width: 568px;
  }

  &.short {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.halfwidth {
    width: 50%;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
    background-color: ${(props) => props.theme.v2.button.dark.disabledColor};
    color: ${(props) => props.theme.v2.button.dark.textColor};
  }

  &.animateArrow {
    span.arrow-icon {
      transform: translateX(7px);
    }
  }

  @keyframes bounceLeft {
    0%,
    25%,
    75%,
    100% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(10px);
    }
    65% {
      transform: translateX(-5px);
    }
  }

  &:hover {
    span.arrow-icon {
      animation: bounceLeft 2s ease 1;
    }

    &.disabled:hover {
      span.arrow-icon {
        animation: none;
      }
    }
  }
`

const StyledArrowImg = styled.span`
  width: 16px;
  height: 12px;
  margin-left: 10px;
  float: right;
  margin-top: 6px;
  background-size: contain;
  background-repeat: no-repeat;
`

type ButtonSize = 'fullwidth' | 'normal' | 'halfwidth' | 'fullwidth-less-padding'
type ButtonHeight = 'short' | 'normal'
export type ButtonStyles = 'bright-border' | 'solid' | 'primary' | 'secondary' | 'dark' | 'bright' | 'white'

interface ButtonProps {
  id?: string
  url: string
  style: ButtonStyles
  target: string
  text: string
  size: ButtonSize
  testid?: string
  withArrow?: boolean
  animateArrow?: boolean
  disabled?: boolean
  height: ButtonHeight
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  preventDefault?: boolean
}

const defaultProps = {
  url: '#',
  style: 'dark',
  target: '_self',
  text: 'Button Text',
  size: 'normal',
  height: 'normal',
  withArrow: false,
  animateArrow: false,
  preventDefault: false,
}

export const Button = (props: ButtonProps) => {
  const classNames = `${props.style} ${props.size} ${props.height} ${props.disabled ? 'disabled' : ''} ${
    props.animateArrow ? 'animateArrow' : ''
  }`

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.preventDefault) {
      event.preventDefault()
    }
    if (!props.disabled && props.onClick) {
      props.onClick(event)
    }
  }

  return (
    <StyledAnchor
      href={props.url}
      aria-label={props.text}
      id={props.id}
      className={`button ${classNames}`}
      target={props.target}
      data-testid={props.testid}
      onClick={handleOnClick}
    >
      {props.text}
      {props.withArrow && <StyledArrowImg className="arrow-icon" />}
    </StyledAnchor>
  )
}
Button.defaultProps = defaultProps
